import { getDefaultTrackerData, SatuEventArgs } from './shared'
import { getSessionId } from '../misc'
import { sendWelcomeMessage } from '../string'

declare const window: {
  __isBot: boolean
} & Window

// Data Portal use centralized event tracker schema
const SATU_EVENT_NAME = 'data_portal_event_tracker_v1'
const SATU_EVENT_GLOBAL = '__satuEvent'

/**
 * Generate request body for satu event tracker
 *
 * @param eventName event name, should be match with the proto specs
 * @param eventParams addiotional event parameter
 * @returns requestBody for http request
 */
const _generateRequestBody = ({
  id,
  pageTitle,
  activityType,
  eventParams = {},
}: SatuEventArgs) => {
  const objectParams = {
    ...eventParams,
  }

  // Convert to Array of key-value item
  const attributes = Object.keys(objectParams)
    .map((key) => {
      const val = objectParams[key]
      if (typeof val !== 'undefined' && val !== null) {
        return { key, value: objectParams[key]?.toString() }
      }

      return null
    })
    .filter(Boolean)
  const sid = getSessionId()

  const requestBody = {
    event_name: SATU_EVENT_NAME,
    data: JSON.stringify({
      ...getDefaultTrackerData(),
      activity_type: activityType,
      page: pageTitle,
      id,
      sid,
      attributes,
    }),
  }

  return requestBody
}

/**
 * Internal fn: push the satu event to the pooling
 *
 * @param pageTitle event name, should be match with the proto specs
 * @param eventParams addiotional event parameter
 * @returns void
 */
const _pushBatchSatuEvent = ({
  id,
  pageTitle,
  activityType,
  eventParams = {},
}: SatuEventArgs) => {
  const requestBody = _generateRequestBody({
    id,
    pageTitle,
    activityType,
    eventParams,
  })

  // @ts-ignore
  if (window[SATU_EVENT_GLOBAL] && Array.isArray(window[SATU_EVENT_GLOBAL])) {
    // @ts-ignore
    window[SATU_EVENT_GLOBAL]?.push(requestBody)
  } else {
    // @ts-ignore
    window[SATU_EVENT_GLOBAL] = window[SATU_EVENT_GLOBAL] || []
    // @ts-ignore
    window[SATU_EVENT_GLOBAL]?.push(requestBody)
  }
}

/**
 * Trigger to send the pooling to the server manually
 * In our case, it called in "beforeHistoryChange"
 *
 * @returns void
 */
export const sendBatchDataToSatuEventApi = () => {
  if (window[SATU_EVENT_GLOBAL] && window[SATU_EVENT_GLOBAL].length > 0) {
    const requestBody = window[SATU_EVENT_GLOBAL]
    const isBot = window.__isBot ?? false

    if (!isBot) {
      if (typeof navigator.sendBeacon !== 'undefined') {
        navigator.sendBeacon(
          '/api/satu-events?batch=true',
          JSON.stringify(requestBody)
        )
        // eslint-disable-next-line no-console
        console.debug(
          '[SatuEvent] Sending tracker via beacon!',
          requestBody.map((i) => ({
            data: JSON.parse(i.data),
            event_name: i.event_name,
          }))
        )
        window[SATU_EVENT_GLOBAL] = []
      } else {
        fetch('/api/satu-events?batch=true', {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(() => {
            // eslint-disable-next-line no-console
            console.debug(
              '[SatuEvent] Sending batch request tracker!',
              requestBody.map((i) => ({
                data: JSON.parse(i.data),
                event_name: i.event_name,
              }))
            )
            // reset pool
            // @ts-ignore
            window[SATU_EVENT_GLOBAL] = []
          })
          .catch(() => {
            // reset pool
            // @ts-ignore
            window[SATU_EVENT_GLOBAL] = []
          })
      }
    }
  }
}

let satuEventInitialized = false
/**
 * Initialization the pooling mechanism for batch request
 * It will loop for certain time (15 second)
 * and will send all the tracker later together
 *
 * Also adding a listener before the page unload, to send the trackers if still exist
 *
 * @returns void
 */
export const initPoolinScheduleSatuEvent = () => {
  // @ts-ignore
  window[SATU_EVENT_GLOBAL] = window[SATU_EVENT_GLOBAL] || []

  // Send tracker via beacon, before unloaded page
  document.addEventListener('visibilitychange', function logData() {
    if (document.visibilityState === 'hidden') {
      sendBatchDataToSatuEventApi()
    }
  })

  if (!satuEventInitialized) {
    sendWelcomeMessage()
    // eslint-disable-next-line no-console
    console.debug('[SatuEvent] Enable satu event tracker!')
  }
  satuEventInitialized = true
}

/**
 * Sending the satu event tracker to the pooling
 *
 * @param eventName event name, should be match with the proto specs
 * @param eventParams addiotional event parameter
 * @returns void
 */
export const sendTrackerSatuEvent = ({
  id,
  pageTitle,
  activityType,
  eventParams = {},
}: SatuEventArgs) => {
  // eslint-disable-next-line no-console
  console.debug('[SatuEvent] Send to pool!', {
    pageTitle,
    activityType,
    eventParams,
  })
  _pushBatchSatuEvent({
    id,
    pageTitle,
    activityType,
    eventParams,
  })
}
