import { getTimestampForGTag } from '../date'
import { getDeviceId } from '../misc'

export type TrackerPageIndentifier =
  | 'Dataset'
  | 'Publikasi'
  | 'Data Induk'
  | 'Metadata'
  | 'Platform Prioritas'
  | 'Tematik'
  | 'Beranda'
  | 'Rapor Publik Homepage'
  | 'Rapor Publik Preview'

export const TrackerSubSection = {
  PAUD_DIKDASMEN: 'PAUD DIKDASMEN',
  PD_DIKTI: 'PD DIKTI',
} as const

export type TrackerSubSectionType = 'PAUD DIKDASMEN' | 'PD DIKTI'

export const TrackerSection = {
  SATPEN: 'Satuan Pendidikan',
  YAYASAN: 'Yayasan',
  PESERTA_DIDIK: 'Peserta Didik',
  PTK: 'PTK',
} as const

export type TrackerSectionType =
  | 'Satuan Pendidikan'
  | 'Yayasan'
  | 'Peserta Didik'
  | 'PTK'

export const LocationLevelEnum = {
  NASIONAL: 'Nasional',
  PROVINSI: 'Provinsi',
  KABUPATEN: 'Kabupaten',
  KECAMATAN: 'Kecamatan',
} as const

export type LocationLevel = 'Nasional' | 'Provinsi' | 'Kabupaten' | 'Kecamatan'

export type GAEventName =
  | 'dataset'
  | 'publikasi'
  | 'induk'
  | 'metadata'
  | 'prioritas'
  | 'tematik'
  | 'beranda'
  | 'rapor_publik_homepage'
  | 'rapor_publik_preview'

export type TrackerEventAction =
  | 'PAGE_VIEWED'
  | 'TABLE_VIEWED'
  | 'SEARCH_BUTTON_CLICKED'
  | 'PREVIEW_BUTTON_CLICKED'
  | 'DOWNLOAD_BUTTON_CLICKED'
  | 'FAQ_LINK_CLICKED'
  | 'PAGE_FILTER_APPLIED'
  | 'NPSN_DETAIL_OPENED'
  | 'PLATFORM_LINK_CLICKED'
  | 'DASHBOARD_LINK_CLICKED'
  | 'NPYP_DETAIL_OPENED'
  | 'AGG_DETAIL_OPENED'
  | 'SURVEY_CLOSE_BUTTON_CLICKED'
  | 'SURVEY_SUBMIT_BUTTON_CLICKED'
  | 'SURVEY_VIEWED'
  | 'REDIRECT_LINK_CLICKED'
  | 'RAPOR_BUTTON_CLICKED'
  | 'FAQ_BUTTON_CLICKED'
  | 'SCROLL_UP'
  | 'IMPRESSION'
  | 'SHARE_BUTTON_CLICKED'
  | 'SHARE_METHOD_SELECTED'
  | 'COPY_BUTTON_CLICKED'

export type GATrackerEventAction =
  | 'EMPTY_TABLE_VIEWED'
  | 'FILTER_WILAYAH_SELECTED'
  | 'FILTER_WILAYAH_OPENED'
  | 'VIEW_MORE_BUTTON_CLICKED'

export interface GATrackEventArgs {
  id: string
  eventName: GAEventName
  eventAction: TrackerEventAction | GATrackerEventAction
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
}

export interface SatuEventArgs {
  id: string
  pageTitle: TrackerPageIndentifier
  activityType: TrackerEventAction
  eventParams?: {
    [key: string]: string | number
  }
}

export const getDefaultTrackerData = () => {
  const timestamp = getTimestampForGTag()
  const did = getDeviceId()
  return {
    did,
    timestamp,
  }
}
