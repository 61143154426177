import { nanoid } from 'nanoid'
import { v4 as uuidv4 } from 'uuid'

import { STORAGE_KEYS } from '@/configs/constants'

/**
 * // https://dev.to/nombrekeff/download-file-from-blob-21ho
 * @param blob <Blob>
 * @param filename <string>
 */
export function downloadBlob(blob: Blob, filename: string) {
  if (typeof URL.createObjectURL === 'function') {
    const safeFileName = filename.replace(/\//g, '_')
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob)

    // Create a link element
    const link = document.createElement('a')

    // Set link's href to point to the Blob URL
    link.href = blobUrl
    link.download = safeFileName
    link.setAttribute('data-filename', safeFileName)
    link.style.display = 'none'

    // Append link to the body
    document.body.appendChild(link)

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    )

    // Remove link from body
    document.body.removeChild(link)
    // Revoke after usage
    URL.revokeObjectURL(blobUrl)
  }
}

export const downloadPdfFromCDN = async ({
  url,
  filename,
  onSuccess,
  onFailed,
}) => {
  try {
    const responseRaw = await fetch(url)
    if (responseRaw.ok) {
      const responseBlob = await responseRaw.blob()
      downloadBlob(responseBlob, filename)

      onSuccess?.()
    } else {
      onFailed?.()
    }
  } catch {
    onFailed?.()
  }
}

export function getDeviceId() {
  const savedUid = localStorage.getItem(STORAGE_KEYS.DEVICE_ID)

  if (!savedUid) {
    const newId = 'did' + nanoid()
    localStorage.setItem(STORAGE_KEYS.DEVICE_ID, newId)
    return newId
  } else {
    return savedUid
  }
}

declare const window: {
  __DPSID: string
} & Window

export function getSessionId() {
  if (window.__DPSID) {
    return window.__DPSID
  } else {
    const newSid = 'sid' + nanoid()
    window.__DPSID = newSid
    return newSid
  }
}

export function getUniqueId() {
  return uuidv4()
}
