import DOMPurify from 'dompurify'

import {
  APP_SEMANTIC_VERSION,
  APP_SHORT_VERSION,
  APP_VERSION,
  BUILD_UTC_TIME,
} from '@/configs/constants'

export const removeNonAlphabet = (str) => str.replace(/[^a-zA-Z ]/g, '')

const keepOnLowerCaseList = {
  akan: 1,
  ala: 1,
  and: 1,
  bagi: 1,
  bak: 1,
  dalam: 1,
  dan: 1,
  'dari pada': 1,
  dari: 1,
  daripada: 1,
  dengan: 1,
  di: 1,
  hingga: 1,
  karena: 1,
  ke: 1,
  kepada: 1,
  mengenai: 1,
  oleh: 1,
  pada: 1,
  per: 1,
  pun: 1,
  sampai: 1,
  sebab: 1,
  secara: 1,
  sehingga: 1,
  serta: 1,
  tapi: 1,
  tentang: 1,
  terhadap: 1,
  tetapi: 1,
  untuk: 1,
  yaitu: 1,
  yang: 1,
}

const satdikList = { sd: 1, smp: 1, sma: 1, smk: 1, paud: 1, ipa: 1 }
export const titleCaseContainsSatdik = (value: string) => {
  return value?.replace(/\w\S*/g, (txt: string) => {
    const key = txt.toLowerCase().replace(/\(|\)/g, '')
    if (satdikList[key]) {
      return txt.toUpperCase()
    }

    if (keepOnLowerCaseList[key]) {
      return txt.toLowerCase()
    }

    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  })
}

const SANITIZE_CONFIG = {
  USE_PROFILES: { html: false, mathMl: false, svg: false },
}

export const xssSafeString = (keyword: string) => {
  if (typeof window !== 'undefined') {
    const cleanKeyword = DOMPurify?.sanitize(keyword, SANITIZE_CONFIG)
    return cleanKeyword || ''
  } else {
    // Skip DOM Purify process in the server side
    return keyword
  }
}

export const formatNumber = (value: number) =>
  new Intl.NumberFormat('id-ID', {}).format(value)

export function withString(str?: string, separator = ' - ') {
  return `${str ? separator + str : ''}`
}

export function jsonSafeParse<ValueType>(jsonString, fallbackVal?) {
  try {
    return JSON.parse(jsonString) as ValueType
  } catch (e) {
    return fallbackVal
  }
}

export const sendWelcomeMessage = () => {
  // eslint-disable-next-line no-console
  console.log(
    '%c👋 Selamat Datang di Portal Data Kemendikbudristek',
    'color: white; background-color: #103178;font-size: 28px;'
  )

  // eslint-disable-next-line no-console
  console.debug({
    version: APP_SEMANTIC_VERSION,
    shortSha: APP_SHORT_VERSION,
    sha: APP_VERSION,
    deployTime: BUILD_UTC_TIME,
    env: process.env.NEXT_PUBLIC_ENV,
  })
}

function isNumber(value) {
  return !isNaN(Number(value))
}

export const THIS_YEAR = new Date().getFullYear()
export const LAST_YEAR = new Date().getFullYear() - 1

export const isValidYear = (year: string, min = 1971, max = THIS_YEAR) => {
  if (isNumber(year)) {
    const yearNumber = Number(year)
    if (yearNumber < min) {
      return false
    }

    if (yearNumber > max) {
      return false
    }
    return true
  }

  return false
}
