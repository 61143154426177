import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/id'

dayjs.locale('id')
dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)

export const DATE_TIME_UTC_FORMAT = 'YYYY-MM-DD HH:mm:ss Z z'
export const DATE_TIME_NON_UTC_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_TIME_LONG_FORMAT = 'DD MMM YYYY HH:mm'

export function formatDate(dateString) {
  return dayjs(dateString).utc().local().format(DATE_TIME_LONG_FORMAT)
}

export function formatDateOnly(dateString) {
  return dayjs(dateString).utc().local().format('DD MMM YYYY')
}

export function formatDateYYYYMMDD(dateString) {
  return dayjs(dateString).utc().local().format('YYYYMMDD')
}

export function getTimestampForGTag() {
  const date = new Date()
  return dayjs(date).utc().local().format(DATE_TIME_NON_UTC_FORMAT)
}
