export const KEMENDIKBUD_URL = 'https://data.kemdikbud.go.id'
export const API_KEMENDIKBUD_URL = 'https://api.data.kemdikbud.go.id'

export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || ''
export const ASSET_PREFIX = process.env.NEXT_PUBLIC_ASSET_PREFIX || ''
export const BE_BASE_URL = '/data-portal-backend'
export const API_BE_BASE_URL = `${process.env.NEXT_PUBLIC_DATA_API_URL}${BE_BASE_URL}`

export const FAQ_LINKS = {
  COMMON:
    'https://pusatinformasi.raporpendidikan.kemdikbud.go.id/hc/en-us/articles/26579557341849-Mengenal-Portal-Satu-Data',
  // AKSES_DASHBOARD_PRIORITAS:
  //   'https://pusatinformasi.raporpendidikan.kemdikbud.go.id/hc/en-us/articles/22436281379609-Akses-Dasbor-Platform-Prioritas',
}

export const HOME_ROUTE = '/'

export const DATASET_ROUTE = '/dataset'
export const PUBLIKASI_ROUTE = '/publikasi'
export const DATA_INDUK_ROUTE = '/data-induk'
export const DATA_INDUK_YAYASAN_ROUTE = '/data-induk/yayasan'
export const DATA_INDUK_PTK_ROUTE = '/data-induk/ptk'
export const METADATA_ROUTE = '/metadata'
export const ROOT_DASHBOARD_PRIORITAS_ROUTE = '/dashboard-prioritas'
export const ROOT_RAPORPUBLIK_ROUTE = '/raporpublik'

export const ConfigCacheOneDay = {
  staleTime: 24 * 60 * 60 * 1000, // 24 hours
  gcTime: 60 * 1000, // 1 minute
}

export const APP_VERSION = process.env.NEXT_PUBLIC_SHA || 'unknown'
export const APP_SHORT_VERSION = APP_VERSION.substring(0, 7)
export const APP_SEMANTIC_VERSION =
  process.env.NEXT_PUBLIC_SEMANTIC_VERSION || '0.0.0'

const TODAY_ISO_STRING = new Date().toISOString()
const BUILD_TIME_ENV = process.env.NEXT_PUBLIC_BUILD_TIME || TODAY_ISO_STRING
const BUILD_TIME =
  BUILD_TIME_ENV === 'development' ? TODAY_ISO_STRING : BUILD_TIME_ENV

export const BUILD_UNIX_TIME = new Date(BUILD_TIME).getTime()
export const BUILD_UTC_TIME = new Date(BUILD_TIME).toUTCString()

export const PLACEHOLDER_SRC = `${ASSET_PREFIX}/images/placeholder.png`

type MetatagItem = {
  title: string
  seoTitle?: string
  description: string
  seoDescription: string
}

export const METATAG: Record<
  | 'PRIORITAS'
  | 'PUBLIKASI'
  | 'STATISTIK'
  | 'DATA_INDUK_YAYASAN'
  | 'DATA_INDUK_SATPEN'
  | 'DATA_INDUK_PTK'
  | 'METADATA',
  MetatagItem
> = {
  PRIORITAS: {
    title: 'Apa itu Platform Prioritas?',
    seoTitle: 'Platform Prioritas',
    description:
      'Platform prioritas merupakan layanan berbasis digital yang dikembangkan untuk mendukung program prioritas pada Kemendikbudristekdikti. Laman ini menyajikan daftar dasbor yang berisi data pencapaian pada platform prioritas. Data yang ditampilkan dalam dasbor ini bersifat terbatas dan hanya bisa diakses sesuai dengan prosedur yang berlaku.',
    seoDescription:
      'Platform prioritas merupakan layanan berbasis digital yang dikembangkan untuk mendukung program prioritas pada Kemendikbudristekdikti. Laman ini menyajikan daftar dasbor yang berisi data pencapaian pada platform prioritas. Data yang ditampilkan dalam dasbor ini bersifat terbatas dan hanya bisa diakses sesuai dengan prosedur yang berlaku.',
  },
  PUBLIKASI: {
    title: 'Publikasi',
    description: `Laman Publikasi merupakan konsolidasi seluruh buku publikasi laporan atau studi terkait Pendidikan dan Kebudayan yang dikeluarkan oleh Kementerian Pendidikan, Kebudayaan, Riset dan Teknologi. Informasi pada publikasi dikelola secara langsung oleh tim Satu Data Pendidikan dan Kebudayaan yang merupakan kebijakan tata kelola data untuk menghasilkan informasi yang akurat, mutakhir, terpadu, dan dapat dipertanggungjawabkan. Seluruh buku publikasi dapat diunduh untuk dipergunakan atau disebarluaskan oleh publik.`,
    seoDescription:
      'Laman Publikasi merupakan konsolidasi seluruh buku publikasi laporan atau studi terkait Pendidikan dan Kebudayan yang dikeluarkan oleh Kementerian Pendidikan, Kebudayaan, Riset dan Teknologi. Informasi pada publikasi dikelola secara langsung oleh tim Satu Data Pendidikan dan Kebudayaan yang merupakan kebijakan tata kelola data untuk menghasilkan informasi yang akurat, mutakhir, terpadu, dan dapat dipertanggungjawabkan. Seluruh buku publikasi dapat diunduh untuk dipergunakan atau disebarluaskan oleh publik.',
  },
  STATISTIK: {
    title: 'Dataset',
    description: `Laman Dataset merupakan konsolidasi semua publikasi statistik resmi dari Kementerian Pendidikan, Kebudayaan, Riset dan Teknologi. Data statistik yang ditampilkan diperbarui setiap tahun pada akhir bulan November di tahun berjalan untuk tahun ajaran yang dimulai pada tahun tersebut. Pada Portal Data Kemendikbudristek, statistik yang dipublikasikan merupakan statistik khusus untuk Pendidikan Anak Usia Dini, Pendidikan Dasar dan Pendidikan Menengah (Pauddasmen). Statistik dibagi sesuai dengan jenjang, yaitu SD, SMP, SMA dan SMK.`,
    seoDescription:
      'Laman Dataset merupakan konsolidasi semua publikasi statistik resmi dari Kementerian Pendidikan, Kebudayaan, Riset dan Teknologi. Data statistik yang ditampilkan diperbarui setiap tahun pada akhir bulan November di tahun berjalan untuk tahun ajaran yang dimulai pada tahun tersebut. Pada Portal Data Kemendikbudristek, statistik yang dipublikasikan merupakan statistik khusus untuk Pendidikan Anak Usia Dini, Pendidikan Dasar dan Pendidikan Menengah (Pauddasmen). Statistik dibagi sesuai dengan jenjang, yaitu SD, SMP, SMA dan SMK.',
  },
  DATA_INDUK_SATPEN: {
    title: 'Data Induk Satuan Pendidikan',
    description:
      'Laman Data Induk Satuan Pendidikan pada Portal Data Kemendikbudristek menyajikan data induk satuan pendidikan untuk Pendidikan Anak Usia Dini, Pendidikan Dasar dan Pendidikan Menengah. Data Induk dibagi sesuai dengan Provinsi dan dapat dibagi lebih lanjut sesuai dengan jalur, bentuk, status dan jenis pendidikan.',
    seoDescription:
      'Laman Data Induk Satuan Pendidikan pada Portal Data Kemendikbudristek menyajikan data induk satuan pendidikan untuk Pendidikan Anak Usia Dini, Pendidikan Dasar dan Pendidikan Menengah. Data Induk dibagi sesuai dengan Provinsi dan dapat dibagi lebih lanjut sesuai dengan jalur, bentuk, status dan jenis pendidikan.',
  },
  DATA_INDUK_YAYASAN: {
    title: 'Data Induk Badan Penyelenggara Satuan Pendidikan (Yayasan)',
    description:
      'Laman Data Induk Badan Penyelenggara Satuan Pendidikan pada Portal Data Kemendikbudristek menyajikan data induk yayasan. Data Induk dibagi sesuai dengan Provinsi dan dapat dibagi lebih lanjut sesuai dengan jalur, bentuk, status dan jenis pendidikan.',
    seoDescription:
      'Laman Data Induk Badan Penyelenggara Satuan Pendidikan pada Portal Data Kemendikbudristek menyajikan data induk yayasan. Data Induk dibagi sesuai dengan Provinsi dan dapat dibagi lebih lanjut sesuai dengan jalur, bentuk, status dan jenis pendidikan.',
  },
  DATA_INDUK_PTK: {
    title: 'Data Induk Pendidik dan Tenaga Kependidikan (PTK)',
    description:
      'Laman Data Induk Pendidik dan Tenaga Kependidikan pada Portal Data Kemendikbudristek menyajikan data induk PTK. Data Induk dibagi sesuai dengan Provinsi dan dapat dibagi lebih lanjut sesuai dengan jalur, bentuk, status dan jenis pendidikan.',
    seoDescription:
      'Laman Data Induk Pendidik dan Tenaga Kependidikan pada Portal Data Kemendikbudristek menyajikan data induk PTK. Data Induk dibagi sesuai dengan Provinsi dan dapat dibagi lebih lanjut sesuai dengan jalur, bentuk, status dan jenis pendidikan.',
  },
  METADATA: {
    title: 'Metadata',
    description:
      'Laman metadata menyajikan Standar Data dan Metadata dari Data Induk yang tertampil pada Portal Data Kemendikbudristek, dalam hal ini data induk satuan pendidikan untuk Paud Dikdasmen. Pada laman ini, standar data merupakan standar yang mendasari Data tertentu. Informasi yang disajikan untuk standar data termasuk uraian mengenai konsep, definisi, klasifikasi, unit dan ukuran untuk data satuan pendidikan. Metadata adalah informasi dalam bentuk struktur dan format yang baku untuk menggambarkan Data, menjelaskan Data, serta memudahkan pencarian, penggunaan, dan pengelolaan informasi Data.',
    seoDescription:
      'Laman metadata menyajikan Standar Data dan Metadata dari Data Induk yang tertampil pada Portal Data Kemendikbudristek, dalam hal ini data induk satuan pendidikan untuk Paud Dikdasmen. Pada laman ini, standar data merupakan standar yang mendasari Data tertentu. Informasi yang disajikan untuk standar data termasuk uraian mengenai konsep, definisi, klasifikasi, unit dan ukuran untuk data satuan pendidikan. Metadata adalah informasi dalam bentuk struktur dan format yang baku untuk menggambarkan Data, menjelaskan Data, serta memudahkan pencarian, penggunaan, dan pengelolaan informasi Data.',
  },
}

export const STORAGE_KEYS = {
  SESSION_ID: '__dpsid',
  DEVICE_ID: '__dpdid',
  WHITELISTED_FF: 'give-me-access',
  RP_KUESIONER: '_rp-qs',
  RP_PDF_ERROR_COUNT: '_rp-pdf-ec',
}

export const SHIMMER_GENERATED_CLASSNAME = 'Shimmer-module_shimmer__3pOKa'

export const DEFAULT_METADATA = {
  title: 'Portal Data Kemendikbudristek',
  description:
    'Satu Data Pendidikan dan Kebudayaan adalah kebijakan tata kelola data untuk menghasilkan informasi yang akurat, mutakhir, terpadu, dapat dipertanggungjawabkan, diakses, dan dibagipakaikan.',
  url: BASE_URL,
  type: 'website',
  image: `${ASSET_PREFIX}/images/meta-banner.png`,
}

export const DEFAULT_METADATA_RAPOR_PUBLIK = {
  title: 'Rapor Pendidikan Publik',
  description:
    'Kenali kualitas layanan pendidikan Indonesia dengan Rapor Pendidikan dan mulai berperan dalam upaya meningkatkan kualitasnya secara berkelanjutan.',
  url: `${BASE_URL}${ROOT_RAPORPUBLIK_ROUTE}`,
  type: 'website',
  image: `${ASSET_PREFIX}/images/raporpublik/meta-rapor-publik-default.png`,
}

export const DEFAULT_METATAG_OPENGRAPH = {
  siteName: DEFAULT_METADATA.title,
  title: `${DEFAULT_METADATA.title}`,
  description: DEFAULT_METADATA.description,
  url: '/',
  images: [DEFAULT_METADATA.image],
  authors: 'Kemdikbud_RI',
}

export const DEFAULT_METATAG_TWITTER = {
  card: 'summary_large_image',
  site: '@Kemdikbud_RI',
  title: `${DEFAULT_METADATA.title}`,
  description: DEFAULT_METADATA.description,
  images: [DEFAULT_METADATA.image],
}

export const DEFAULT_METATAG_OPENGRAPH_RAPORPUBLIK = {
  siteName: DEFAULT_METADATA_RAPOR_PUBLIK.title,
  title: `${DEFAULT_METADATA_RAPOR_PUBLIK.title}`,
  description: DEFAULT_METADATA_RAPOR_PUBLIK.description,
  url: `${BASE_URL}${ROOT_RAPORPUBLIK_ROUTE}`,
  images: [DEFAULT_METADATA_RAPOR_PUBLIK.image],
  authors: 'Kemdikbud_RI',
}

export const DEFAULT_METATAG_TWITTER_RAPORPUBLIK = {
  card: 'summary_large_image',
  site: '@Kemdikbud_RI',
  title: `${DEFAULT_METADATA_RAPOR_PUBLIK.title}`,
  description: DEFAULT_METADATA_RAPOR_PUBLIK.description,
  images: [DEFAULT_METADATA_RAPOR_PUBLIK.image],
}

export const UTM_SOURCE_FROM_PSD = `utm_source=portal-satu-data`

// https://www.toptal.com/developers/keycode/escape
// https://www.tutorialspoint.com/detecting-arrow-key-presses-in-javascript#:~:text=The%20key%20codes%20for%20the,39%2C%20and%2040%2C%20respectively.
export const KEYBOARD_KEYCODE = {
  ENTER: 13,
  ESCAPE: 27,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
}
